import React, { useState, useEffect } from 'react';

function VaccineList() {
    const [vaccines, setVaccines] = useState([]);
    const [newVaccine, setNewVaccine] = useState({
        name: '',
        code: '',
        protectionStartDate: '',
        protectionFinishDate: '',
        animalId: ''
    });
    const [editingVaccine, setEditingVaccine] = useState(null);

    useEffect(() => {
        fetchVaccines();
    }, []);

    const fetchVaccines = async () => {
        try {
            const response = await fetch('/api/v1/vaccine');
            if (!response.ok) throw new Error('Failed to fetch vaccines');
            const data = await response.json();
            setVaccines(data);
        } catch (error) {
            console.error('Fetch error:', error);
        }
    };

    const handleInputChange = (e) => {
        setNewVaccine({ ...newVaccine, [e.target.name]: e.target.value });
    };

    const handleAddVaccine = async () => {
        try {
            const response = await fetch('/api/v1/vaccine/create', {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify(newVaccine)
            });
            if (!response.ok) throw new Error('Failed to add vaccine');
            await fetchVaccines();
            setNewVaccine({ name: '', code: '', protectionStartDate: '', protectionFinishDate: '', animalId: '' });
        } catch (error) {
            console.error('Add error:', error);
        }
    };
    const startEdit = (vaccine) => {
        setEditingVaccine({ ...vaccine });
    };

    const handleEditChange = (e) => {
        setEditingVaccine({ ...editingVaccine, [e.target.name]: e.target.value });
    };

    const handleEditSubmit = async (e) => {
        e.preventDefault();
        try {
            const response = await fetch(`/api/v1/vaccine/update/${editingVaccine.id}`, {
                method: 'PUT',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify(editingVaccine)
            });
            if (!response.ok) throw new Error('Failed to update vaccine');
            setEditingVaccine(null);
            await fetchVaccines();
        } catch (error) {
            console.error('Edit error:', error);
        }
    };

    

    const handleDeleteVaccine = async (vaccineId) => {
        try {
            const response = await fetch(`/api/v1/vaccine/delete/${vaccineId}`, {
                method: 'DELETE'
            });
            if (!response.ok) throw new Error('Failed to delete vaccine');
            await fetchVaccines();
        } catch (error) {
            console.error('Delete error:', error);
        }
    };

    return (
        <div>

            <div>

  
   
        </div>

        <div>

        {/* Düzenleme formu */}
        {editingVaccine && (
            <form onSubmit={handleEditSubmit}>
                <input type="text" name="name" placeholder="Name" value={editingVaccine.name} onChange={handleEditChange} />
                <input type="text" name="code" placeholder="Code" value={editingVaccine.code} onChange={handleEditChange} />
                {/* Diğer input alanları */}
                <button type="submit">Update Vaccine</button>
            </form>
        )}

    </div>

         
            
        </div>

        
    );
}

export default VaccineList;
