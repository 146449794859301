import React, { useState, useEffect } from 'react';
import { AppointmentService } from '../../services/AppointmentService';
import './Appointment.css';



function AppointmentList() {
    const [appointments, setAppointments] = useState([]);
    const [newAppointment, setNewAppointment] = useState({ appointmentDate: '', animalId: '', doctorId: '' });
    const [updateAppointment, setUpdateAppointment] = useState({ id: null, appointmentDate: '', animalId: '', doctorId: '' });

    useEffect(() => {
        const fetchAppointments = async () => {
            try {
                const data = await AppointmentService.getAllAppointments();
    
                const appointmentsWithDetails = await Promise.all(data.map(async (appointment) => {
                    const doctorName = await fetchDoctorById(appointment.doctorId);
                    const animalName = await fetchAnimalById(appointment.animalId);
                    return { ...appointment, doctorName, animalName };
                }));
    
                setAppointments(appointmentsWithDetails);
            } catch (error) {
             
            }
        };
    
        fetchAppointments();
    }, []);
    



    const fetchDoctorById = async (doctorId) => {
        try {
            const response = await fetch(`http://localhost:3000/api/v1/doctor/${doctorId}`);
            if (!response.ok) {
                throw new Error('Doktor bilgisi alınamadı');
            }
            const doctorData = await response.json(); 
            return doctorData.name; 
        } catch (error) {
            console.error('Doktor bilgisi alınırken hata:', error);
            return 'Doktor Bilinmiyor'; 
        }
    };
    
    
    const fetchAnimalById = async (animalId) => {
        try {
            const response = await fetch(`http://localhost:3000/api/animals/${animalId}`);
            if (!response.ok) {
                throw new Error('Hayvan bilgisi alınamadı');
            }
            const animalData = await response.json(); 
            return animalData.name; 
        } catch (error) {
            console.error('Hayvan bilgisi alınırken hata:', error);
            return 'Hayvan Bilinmiyor';
        }
    };

    const handleInputChange = (e) => {
        setNewAppointment({ ...newAppointment, [e.target.name]: e.target.value });
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        fetch('/api/v1/appointmentDate/create-with-doctor-and-animal/' + newAppointment.doctorId + '/' + newAppointment.animalId, {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({ appointmentDate: newAppointment.appointmentDate })
        })
            .then(() => {
        
                fetchAppointments(); 
            })
            .catch(error => console.error('Error adding appointment', error));
    };
    
    
    const handleNewAppointmentSubmit = async (e) => {
        e.preventDefault();
        try {
            await AppointmentService.createAppointment(newAppointment.doctorId, newAppointment.animalId, newAppointment.appointmentDate);
            await fetchAppointments();
            setNewAppointment({ appointmentDate: '', animalId: '', doctorId: '' }); // Reset form
        } catch (error) {
            console.error('Error creating new appointment:', error);
        }
    };
    
    const fetchAppointments = async () => {
        try {
            const data = await AppointmentService.getAllAppointments();
            const appointmentsWithDetails = await Promise.all(data.map(async (appointment) => {
                const doctorName = await fetchDoctorById(appointment.doctorId);
                const animalName = await fetchAnimalById(appointment.animalId);
                return { ...appointment, doctorName, animalName };
            }));
    
            console.log("Randevular ve Detayları:", appointmentsWithDetails); 
            setAppointments(appointmentsWithDetails);
        } catch (error) {
            console.error('Randevu listesi alınırken hata oluştu:', error);
        }
    };
    
    const handleDelete = (id) => {
        fetch('/api/v1/appointmentDate/delete/' + id, {
            method: 'DELETE'
        })
            .then(() => {
             
                setAppointments(appointments.filter(appointment => appointment.id !== id));
            })
            .catch(error => console.error('Error deleting appointment', error));
    };


    const parseDate = (dateTime) => {
        if (!dateTime) {
            return { valid: false, date: null };
        }

        try {
            const [datePart, timePart] = dateTime.split(' ');
            const [day, month, year] = datePart.split('/');
            return { valid: true, date: new Date(`${year}-${month}-${day}T${timePart}`) };
        } catch (error) {
            console.error('Tarih parse edilirken hata:', error);
            return { valid: false, date: null };
        }
    };

    return (
        <div className="appointment-container">

            <div>
  

            <h1 className="mt-3 text-center" style={{ color: "#4F4A45" }}>Randevu Listesi</h1>
            <br />
            

        </div>

            <table className="appointment-table">
                <thead>
                    <tr>
                        <th>ID</th>
                        <th>Tarih</th>
                        <th>Saat</th>
                        <th>Doktor ID</th>
                        <th>Doktor İsmi</th>
                        <th>Hayvan ID</th>
                        <th>Hayvan İsmi</th>
                    </tr>
                </thead>
                <tbody>
                    {appointments.map(appointment => {
                        const { valid, date } = parseDate(appointment.appointmentDate);
                        return (
                            <tr key={appointment.id}>
                                <td>{appointment.id}</td>
                                <td>{valid ? date.toLocaleDateString() : 'Geçersiz Tarih'}</td>
                                <td>{valid ? date.toLocaleTimeString() : 'Geçersiz Saat'}</td>
                                <td>{appointment.doctorId}</td>
                                <td>{appointment.doctorName}</td> 
                                <td>{appointment.animalId}</td>
                                <td>{appointment.animalName}</td>
                            </tr>
                        );
                    })}
                </tbody>
            </table>

            <ul>
                {appointments.map(appointment => (
                    <li key={appointment.id}>
                        {appointment.appointmentDate} - Animal ID: {appointment.animalId}, Doctor ID: {appointment.doctorId}
                        <button onClick={() => handleDelete(appointment.id)}>Delete</button>
                    </li>
                ))}
            </ul>

            <br /><br />

            <h2>Randevu Ekle </h2>
            <form onSubmit={handleSubmit}>
                <input type="datetime-local" name="appointmentDate" value={newAppointment.appointmentDate} onChange={handleInputChange} required />
                <input type="number" name="animalId" placeholder="Hayvan ID" value={newAppointment.animalId} onChange={handleInputChange} required />
                <input type="number" name="doctorId" placeholder="Doktor ID" value={newAppointment.doctorId} onChange={handleInputChange} required />
                <button type="submit">Ekle</button>
            </form>

            
        </div>

        
    );
}

export default AppointmentList;
